import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import { withSnackbar } from "notistack";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import FolderIcon from "@material-ui/icons/Folder";

import fileDownload from "js-file-download";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import EstimateService from "../../services/EstimateService";
import TableAction from "../TableAction";
import TableSortableHeader from "../table/TableSortableHeader";
import { formatCurrency } from "../../utils/numberFormat";
import { privateRoutes } from "../../routes";
import EstimateStatusManager from "./EstimateStatusManager";
import EstimatesListFilter from "./EstimatesListFilter";
import EstimateStatusLabel from "./EstimateStatusLabel";
import MobileConfirmationDialog from "../dialog/MobileConfirmationDialog";
import { ESTIMATE_STATUS } from "../../utils/status";
import PreviewEstimateDialog from "../dialog/PreviewEstimateDialog";
import FinancingService from "../../services/FinancingService";
import EstimateYouSignDialog from "../dialog/EstimateYouSignDialog";
import { contactCanSign } from "../../utils/signature";
import LinkedInvoicesDialog from "../dialog/LinkedInvoicesDialog";
import LinkedAdditionalDocumentsDialog from "../dialog/LinkedAdditionalDocumentsDialog";
import FranfinanceTransferTypeDialog from "../financing/FranfinanceTransferTypeDialog";
import DocumentDivisionDetailsDialog from "../documentDivision/DocumentDivisionDetailsDialog";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  rowDeleted: {
    background: theme.palette.action.disabled,
    color: theme.palette.text.disabled,
    textDecoration: "line-through"
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  rightAlign: {
    textAlign: "right"
  },
  centerAlign: {
    textAlign: "center"
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(224,224,244)"
  }
});

const ROWS_PER_PAGE = 50;
const defaultFilter = {
  q: "",
  userId: "",
  status: [],
  from: null,
  to: null,
  client: ""
};

@inject("sessionStore")
@observer
class EstimatesList extends Component {
  constructor(props) {
    super(props);
    const { filter } = props;
    this.state = {
      currentPage: 0,
      currentSort: null,
      download: "",
      downloadHref: "",
      filter: { ...defaultFilter, ...(filter || {}) },
      gonnaBeRefreshed: false,
      estimates: null,
      openStatusManager: false,
      selectedEstimate: null,
      totalCount: 0,
      menuOpenFor: null,
      openConfirmMobileDialog: false,
      openPreview: false,
      openLinkedInvoices: false,
      // YOUSIGN
      openYouSignDialog: false,
      openLinkedAdditionalDocumentsDialog: false,
      openFranfinanceContextTransferTypeDialog: false,
      // EXPORT
      isExporting: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    this.fetchEstimates();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangePage = (event, page) => {
    if (this._isMounted) {
      this.setState({ currentPage: page }, () => {
        this.fetchEstimates();
      });
    }
  };

  handleShowStatusManager = estimate => {
    this.setState({ openStatusManager: true, selectedEstimate: estimate });
  };

  handleHideStatusManager = () => {
    this.setState({ openStatusManager: false, selectedEstimate: null });
  };

  handleShowConfirmMobileForSignature = estimate => {
    const { sessionStore } = this.props;
    if (sessionStore.user.company.signatureProvider === "CONTRALIA") {
      this.setState({ openConfirmMobileDialog: true, selectedEstimate: estimate });
    }
    if (sessionStore.user.company.signatureProvider === "YOUSIGN") {
      this.setState({ selectedEstimate: estimate, openYouSignDialog: true });
    }
  };

  handleHideConfirmMobileForSignature = () => {
    this.setState({ openConfirmMobileDialog: false, selectedEstimate: null });
  };

  handleCloseYouSignDialog = ({ needRefresh = false } = {}) => {
    this.setState({ openYouSignDialog: false, selectedEstimate: null });
    if (needRefresh) {
      this.fetchEstimates();
    }
  };

  handleDeletion = estimate => {
    EstimateService.deleteEstimate(estimate)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(`Le devis ${estimate.number} a été supprimé`, { variant: "success" });
        this.fetchEstimates();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la suppression du devis", { variant: "error" });
      });
  };

  duplicateEstimate = estimate => {
    EstimateService.duplicateEstimate(estimate.id)
      .then(createdEstimate => {
        const { history } = this.props;
        history.push(privateRoutes.common.estimates.editionForm.path.replace(":estimateId", createdEstimate.id));
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la duplication du devis", { variant: "error" });
      });
  };

  downloadPDF = estimate => {
    EstimateService.downloadPDF(estimate)
      .then(res => {
        const downloadHref = window.URL.createObjectURL(res);
        if (this._isMounted) {
          this.setState({ downloadHref, download: `Devis  - ${estimate.number}.pdf` });
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du devis", { variant: "error" });
      });
  };

  previewPDF = estimate => {
    this.setState({
      openPreview: true,
      selectedEstimate: estimate
    });
  };

  handleHidePreview = () => {
    this.setState({
      openPreview: false,
      selectedEstimate: null
    });
  };

  simulateClick = e => {
    // Avoid a blank page by checking if e is null or not.
    // Just remove the if condition to check the behavior.
    if (e !== null) {
      e.click();
    }
    this.setState({ downloadHref: "" });
  };

  intoInvoice = estimate => {
    EstimateService.intoInvoice(estimate.id)
      .then(invoice => {
        const { enqueueSnackbar } = this.props;
        const { success } = invoice;
        enqueueSnackbar(`La facture associée au devis ${estimate.number} a été créée`, {
          variant: "success"
        });
        const { history } = this.props;
        history.push(privateRoutes.admin.invoices.editionForm.path.replace(":invoiceId", success.id));
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la facturation du devis", { variant: "error" });
      });
  };

  markEstimateAs = ({ estimate, status }) =>
    EstimateService.patchEstimate({ ...estimate, status })
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Le statut du devis a été modifié", { variant: "success" });
        this.fetchEstimates();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour du devis", { variant: "error" });
      });

  sendSofincoContext = orderForm => {
    const { information, contact, number, amount } = orderForm;
    const parsedInformation = JSON.parse(information);
    FinancingService.sendSofincoContext({
      financingForm: parsedInformation.financingForm,
      contact,
      externalId: number,
      amount
    })
      .then(res => {
        window.open(res.url);
      })
      .catch(e => {
        const { enqueueSnackbar } = this.props;
        if (e && e.status === 412) {
          enqueueSnackbar("Une configuration de votre identifiant Sofinco est nécessaire", { variant: "error" });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Sofinco", {
            variant: "error"
          });
        }
      });
  };

  sendFranfinanceContext = (estimate, transferType) => {
    FinancingService.sendFranfinanceContext(estimate, "estimate", transferType)
      .then(res => {
        window.open(res.url);
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Franfinance", {
          variant: "error"
        });
      });
  };

  handleShowFranfinanceContextTransferTypeDialog = estimate => {
    this.setState({ openFranfinanceContextTransferTypeDialog: true, selectedEstimate: estimate });
  };

  handleHideFranfinanceTransferTypeDialog = () => {
    this.setState({ openFranfinanceContextTransferTypeDialog: false, selectedEstimate: null });
  };

  handleSubmitFranfinanceTransferTypeDialog = transferType => {
    const { selectedEstimate } = this.state;
    this.sendFranfinanceContext(selectedEstimate, transferType);
    this.handleHideFranfinanceTransferTypeDialog();
  };

  handleSendEstimate = estimate => {
    EstimateService.sendEstimate(estimate)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(`Le devis ${estimate.number} a été envoyé`, { variant: "success" });
        this.fetchEstimates();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'envoi du devis", { variant: "error" });
      });
  };

  handleSendSignEstimateMail = estimate => {
    EstimateService.sendSignEstimateMail(estimate)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(`Vous venez de relancer le devis ${estimate.number} pour signature`, { variant: "success" });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'envoi du mail", { variant: "error" });
      });
  };

  handleShowLinkedInvoices = estimate => {
    this.setState({ openLinkedInvoices: true, selectedEstimate: estimate });
  };

  handleHideLinkedInvoices = () => {
    this.setState({ openLinkedInvoices: false, selectedEstimate: null });
  };

  handleHideLinkedAdditionalDocumentsDialog = () => {
    this.setState({ openLinkedAdditionalDocumentsDialog: false, selectedEstimate: null });
  };

  handleShowLinkedAdditionalDocumentsDialog = estimate => {
    this.setState({ openLinkedAdditionalDocumentsDialog: true, selectedEstimate: estimate });
  };

  addAdditionalDocument = (estimate, type) =>
    EstimateService.addAdditionalDocument(estimate, type)
      .then(created => {
        const { enqueueSnackbar, history } = this.props;
        enqueueSnackbar("Le document a été créé", { variant: "success" });
        history.push(
          privateRoutes.common.additionalDocuments.worksAcceptanceDetails.path.replace(
            ":additionalDocumentId",
            created.id
          )
        );
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la création du document", { variant: "error" });
      });

  handleArkeaContext = estimate => {
    FinancingService.sendArkeaContext(estimate, "estimate")
      .then(res => {
        window.open(res.url);
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Arkea", {
          variant: "error"
        });
      });
  };

  renderMenu = estimate => {
    const { sessionStore } = this.props;
    if (
      sessionStore.user.id === estimate.user.id ||
      sessionStore.userHasAccess({
        requiredAcls: ["ESTIMATE_WRITE"],
        requiredFeatures: ["ESTIMATE"]
      })
    ) {
      const canInvoice =
        ["DRAFT", "SENT", "SIGNED", "INVOICED"].indexOf(estimate.status) !== -1 &&
        sessionStore.userHasAccess({
          requiredAcls: ["INVOICE_MY"],
          requiredFeatures: ["INVOICE"]
        });
      const canOrder =
        ["DRAFT", "SENT", "SIGNED"].indexOf(estimate.status) !== -1 &&
        sessionStore.userHasAccess({
          requiredAcls: ["ORDER_MY"],
          requiredFeatures: ["ORDER"]
        });
      const userCanAdditionalDocument = sessionStore.userHasAccess({
        requiredFeatures: ["ADDITIONAL_DOCUMENT"],
        requiredAcls: ["ADDITIONAL_DOCUMENT_MY", "ADDITIONAL_DOCUMENT_READ"],
        aclType: "oneof"
      });
      const userCanEndWorksAcceptance =
        sessionStore.userHasAccess({
          requiredFeatures: ["ADDITIONAL_DOCUMENT"],
          requiredAcls: ["ADDITIONAL_DOCUMENT_MY"]
        }) &&
        sessionStore.userHasAccess({
          requiredFeatures: ["OPTION_ADDITIONAL_DOCUMENT_WORKS_ACCEPTANCE"]
        });
      const userCanVatDeclaration =
        sessionStore.userHasAccess({
          requiredFeatures: ["ADDITIONAL_DOCUMENT"],
          requiredAcls: ["ADDITIONAL_DOCUMENT_MY"]
        }) &&
        sessionStore.userHasAccess({
          requiredFeatures: ["OPTION_ADDITIONAL_DOCUMENT_VAT_DECLARATION"]
        });
      const { information } = estimate;
      const parsedInformation = JSON.parse(information);

      return (
        <TableAction
          onOpen={() => {
            this.setState({ menuOpenFor: estimate.id });
          }}
          onClose={() => {
            this.setState({ menuOpenFor: null });
          }}
        >
          <MenuItem key="menuitem-preview" onClick={() => this.previewPDF(estimate)}>
            Prévisualiser
          </MenuItem>
          {estimate.paymentCondition === "FINANCING" &&
            parsedInformation.financingForm.financingType === "SOFINCO" &&
            ["INVOICED", "CANCEL", "SIGNED"].indexOf(estimate.status) !== -1 && (
              <MenuItem onClick={() => this.sendSofincoContext(estimate)}>Accéder à Sofinco</MenuItem>
            )}
          {estimate.paymentCondition === "FINANCING" &&
            parsedInformation.financingForm.financingType === "FRANFINANCE" &&
            ["INVOICED", "CANCEL", "SIGNED"].indexOf(estimate.status) !== -1 && (
              <MenuItem onClick={() => this.handleShowFranfinanceContextTransferTypeDialog(estimate)}>
                Accéder à Franfinance
              </MenuItem>
            )}
          {estimate.paymentCondition === "FINANCING" &&
            parsedInformation.financingForm.financingType === "ARKEA" &&
            ["INVOICED", "CANCEL", "SIGNED"].indexOf(estimate.status) !== -1 && (
              <MenuItem onClick={() => this.handleArkeaContext(estimate)}>Accéder à Arkea</MenuItem>
            )}
          {estimate.status !== "DRAFT" &&
            userCanAdditionalDocument && [
              <Divider key="additional-documents-divider" />,
              estimate.additionalDocuments && estimate.additionalDocuments.length > 0 ? (
                <MenuItem
                  key="additional-documents-list"
                  onClick={() => this.handleShowLinkedAdditionalDocumentsDialog(estimate)}
                >
                  Documents liés
                </MenuItem>
              ) : null,

              userCanEndWorksAcceptance ? (
                <MenuItem
                  key="ad-works-acceptance"
                  onClick={() => this.addAdditionalDocument(estimate, "WORKS_ACCEPTANCE")}
                >
                  Créer un PV de fin de travaux
                </MenuItem>
              ) : null,
              userCanVatDeclaration ? (
                <MenuItem
                  key="ad-vat-declaration"
                  onClick={() => this.addAdditionalDocument(estimate, "VAT_DECLARATION")}
                >
                  Créer une attestation de TVA
                </MenuItem>
              ) : null,
              <Divider key="additional-documents-divider-after" />
            ]}
          {["DRAFT", "SENT"].indexOf(estimate.status) !== -1 &&
            !estimate.signatureRequestId &&
            !estimate.transactionId &&
            sessionStore.userHasAccess({
              requiredAcls: ["SIGN_WRITE"],
              requiredFeatures: ["SIGN"]
            }) && (
              <MenuItem
                disabled={estimate.contact && !contactCanSign(estimate.contact)}
                key="sign-estimate"
                onClick={() => {
                  // DIFFERENT BASED ON SIGNATURE PROVIDER
                  this.handleShowConfirmMobileForSignature(estimate);
                }}
              >
                Envoyer pour signature
              </MenuItem>
            )}
          <MenuItem
            key="send-estimate"
            onClick={() => {
              this.handleSendEstimate(estimate);
            }}
          >
            Envoyer par e-mail
          </MenuItem>
          <Divider key="menuitem-draft-action-divider-i" />
          {estimate.signatureRequestId && !estimate.signatureRequestDoneDate && (
            <MenuItem
              onClick={() => {
                this.setState({ selectedEstimate: estimate, openYouSignDialog: true });
              }}
            >
              Gérer la signature
            </MenuItem>
          )}
          {estimate.signatureRequestId && !estimate.signatureRequestDoneDate && (
            <MenuItem
              onClick={() => {
                this.handleSendSignEstimateMail(estimate);
              }}
            >
              Relance de la signature
            </MenuItem>
          )}
          {["DRAFT", "REFUSED"].indexOf(estimate.status) !== -1 && [
            <MenuItem
              key={`mark-sent-${estimate.id}`}
              onClick={() => this.markEstimateAs({ estimate, status: "SENT" })}
            >
              Marquer comme envoyé
            </MenuItem>,
            <Divider key="menuitem-draft-action-divider-ii" />
          ]}
          {["DRAFT", "SENT"].indexOf(estimate.status) !== -1 && [
            <MenuItem
              key={`accept-estimate-${estimate.id}`}
              onClick={() => this.markEstimateAs({ estimate, status: "SIGNED" })}
            >
              Marquer comme signé
            </MenuItem>,
            <Divider key="menuitem-draft-action-divider-iii" />
          ]}
          {["DRAFT", "SENT"].indexOf(estimate.status) !== -1 && [
            <MenuItem
              key={`mark-refused-${estimate.id}`}
              onClick={() => this.markEstimateAs({ estimate, status: "REFUSED" })}
            >
              Marquer comme refusé
            </MenuItem>,
            <Divider key="menuitem-draft-action-divider-iv" />
          ]}
          {canOrder && (
            <MenuItem
              key="menuitem-as-order"
              component={Link}
              to={{
                pathname: privateRoutes.common.orderForms.creationForm.path,
                state: { fromOrderForm: { ...estimate, limitDate: null } }
              }}
            >
              Transformer en bon
            </MenuItem>
          )}
          {canInvoice && (
            <MenuItem key={`into-invoice-${estimate.id}`} onClick={() => this.intoInvoice(estimate)}>
              Transformer en facture
            </MenuItem>
          )}
          {(canOrder || canInvoice) && <Divider key="menuitem-draft-action-divider-v" />}
          {["SENT", "SIGNED"].indexOf(estimate.status) !== -1 && [
            <ConfirmationDialog
              key={`cancel-estimate-${estimate.id}`}
              situationText="Êtes-vous sûr de vouloir annuler ce devis ?"
              title="Confirmation"
              confirmText="Oui"
              cancelText="Non"
            >
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e => {
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => this.markEstimateAs({ estimate, status: "CANCEL" }))
                    );
                  }}
                >
                  Annuler
                </MenuItem>
              )}
            </ConfirmationDialog>,
            <Divider key="menuitem-draft-action-divider-vi" />
          ]}
          {["INVOICED", "CANCEL", "SIGNED"].indexOf(estimate.status) === -1 &&
            !estimate.transactionId &&
            !estimate.signatureRequestId && (
              <MenuItem
                key="menuitem-edit"
                component={Link}
                to={privateRoutes.common.estimates.editionForm.path.replace(":estimateId", estimate.id)}
              >
                Modifier
              </MenuItem>
            )}
          <MenuItem key="menuitem-download" onClick={() => this.downloadPDF(estimate)}>
            Télécharger
          </MenuItem>
          {estimate.invoices.length > 0 && (
            <MenuItem onClick={() => this.handleShowLinkedInvoices(estimate)}>
              Voir {estimate.invoices.length > 1 ? "les factures" : "la facture"}
            </MenuItem>
          )}
          {["DRAFT", "SENT", "CANCEL", "REFUSED"].indexOf(estimate.status) !== -1 && (
            <ConfirmationDialog situationText="Êtes-vous sûr de vouloir supprimer ce devis ? Attention, cette action est irréversible.">
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e => {
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => this.handleDeletion(estimate))
                    );
                  }}
                >
                  Supprimer
                </MenuItem>
              )}
            </ConfirmationDialog>
          )}
          <MenuItem key="menuitem-duplication" onClick={() => this.duplicateEstimate(estimate)}>
            Dupliquer
          </MenuItem>
        </TableAction>
      );
    }
    // if (
    //   sessionStore.user.company.features.includes("OPTION_DOCUMENT_DIVISION") &&
    //   estimate.divisions &&
    //   estimate.divisions.length > 0 &&
    //   estimate.divisions.some(
    //     documentDivision => documentDivision.user && documentDivision.user.id === sessionStore.user.id
    //   )
    // ) {
    //   return <p>TODO</p>;
    // }
    return null;
  };

  handleSort = arg => {
    if (this._isMounted) {
      this.setState({ currentSort: arg }, () => {
        this.fetchEstimates();
      });
    }
  };

  updateFilter = filter => {
    if (this._isMounted) {
      this.setState({ filter });
    }
  };

  fetchEstimates = () => {
    const { currentSort, filter, currentPage } = this.state;
    const { sessionStore } = this.props;
    this.setState({ gonnaBeRefreshed: true }, () => {
      const ziFilter = {
        ...filter,
        userId: !sessionStore.userHasAccess({
          requiredAcls: ["ESTIMATE_READ"],
          requiredFeatures: ["ESTIMATE"]
        })
          ? sessionStore.user.id
          : filter.userId || "",
        status:
          filter.status.length ===
          Object.keys(ESTIMATE_STATUS).filter(statusKey => ESTIMATE_STATUS[statusKey].hideForFilter !== true).length
            ? ""
            : filter.status.join(","),
        from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
        to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
      };
      EstimateService.getEstimates({
        page: currentPage,
        sort: currentSort ? currentSort.column : undefined,
        order: currentSort ? currentSort.order : undefined,
        filter: ziFilter
      })
        .then(response => {
          if (this._isMounted) {
            this.setState({
              estimates: response.content,
              totalCount: response.totalCount,
              gonnaBeRefreshed: false
            });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des devis", { variant: "error" });
          if (this._isMounted) {
            this.setState({ gonnaBeRefreshed: false });
          }
        });
    });
  };

  handleOpenExportMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseExportMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleExportAsCsv = () => {
    const { filter } = this.state;
    const ziFilter = {
      ...filter,
      status: filter.status.length === Object.keys(ESTIMATE_STATUS).length ? "" : filter.status.join(","),
      from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
      to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
    };
    const { enqueueSnackbar } = this.props;
    this.setState({ isExporting: true, anchorEl: null }, () => {
      EstimateService.exportEstimatesAsCsv({ filter: ziFilter })
        .then(response => {
          fileDownload(response, "export.csv");
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de l'export des devis", { variant: "error" });
        })
        .finally(() => {
          this.setState({ isExporting: false, anchorEl: null });
        });
    });
  };

  render() {
    const { classes, title = "Devis", sessionStore, disableExport = false } = this.props;
    const {
      currentPage,
      currentSort,
      download,
      downloadHref,
      filter,
      gonnaBeRefreshed,
      estimates,
      openStatusManager,
      selectedEstimate,
      totalCount,
      menuOpenFor,
      openConfirmMobileDialog,
      openPreview,
      openYouSignDialog,
      openLinkedInvoices,
      openLinkedAdditionalDocumentsDialog,
      openFranfinanceContextTransferTypeDialog,
      isExporting,
      anchorEl
    } = this.state;
    const { CGV, withdrawal } = sessionStore.user.company;
    const isAccountValid =
      sessionStore.siggnedIn && CGV !== null && CGV !== undefined && withdrawal !== null && withdrawal !== undefined;

    const canExport = sessionStore.userHasAccess({
      requiredAcls: ["ESTIMATE_EXPORT"],
      requiredFeatures: ["ESTIMATE"]
    });
    return (
      <Fragment>
        <Grid container className={classes.container}>
          <Grid item xs={12} style={{ display: "flex", marginBottom: 24 }}>
            <Typography variant="h4">{title}</Typography>
            <div className={classes.buttonsContainer}>
              <Hidden xsDown>
                <Button
                  component={Link}
                  to={privateRoutes.common.estimates.creationForm.path}
                  variant="outlined"
                  color="primary"
                  disabled={!isAccountValid}
                >
                  Nouveau devis
                </Button>
              </Hidden>
            </div>
          </Grid>
          <EstimatesListFilter
            filter={filter}
            defaultFilter={defaultFilter}
            updateFilter={this.updateFilter}
            onSubmit={this.fetchEstimates}
          />
          {!disableExport && canExport && estimates && estimates.length > 0 && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                aria-controls="simple-export-menu"
                aria-haspopup="true"
                disabled={isExporting}
                variant="outlined"
                color="primary"
                style={{ marginTop: 24 }}
                onClick={this.handleOpenExportMenu}
              >
                {isExporting && <CircularProgress style={{ marginRight: 8 }} size={20} />} Exporter la liste
              </Button>
              <Menu
                id="simple-export-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleCloseExportMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <ListItem button onClick={this.handleExportAsCsv}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Format CSV"
                    secondary="Toutes les données principales des devis regroupées dans un tableau"
                  />
                </ListItem>
              </Menu>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.root}>
              {estimates && estimates.length >= 15 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                  className={classes.borderBottom}
                />
              )}
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="estimate.createAt"
                      >
                        Date
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="estimate.number"
                      >
                        Numéro
                      </TableSortableHeader>{" "}
                      /{" "}
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="estimate.nature"
                      >
                        Objet
                      </TableSortableHeader>
                      <span>
                        {" "}
                        /{" "}
                        <TableSortableHeader
                          onClick={this.handleSort}
                          className={classes.sortableHeader}
                          currentSort={currentSort}
                          attribute="user.lastname"
                        >
                          Utilisateur
                        </TableSortableHeader>
                      </span>
                    </TableCell>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="contact.lastname"
                      >
                        Client
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell className={classes.centerAlign}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="estimate.totalPriceWithoutTVA"
                      >
                        Montant HT
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell className={classes.centerAlign}>Montant TVA</TableCell>
                    <TableCell className={classes.centerAlign}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="estimate.totalPriceWithTVA"
                      >
                        Montant TTC
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {estimates && estimates.length >= 0 && gonnaBeRefreshed === false ? (
                    estimates.map(estimate => (
                      <TableRow
                        className={estimate.deleted ? classes.rowDeleted : null}
                        key={estimate.id}
                        selected={estimate.id === menuOpenFor}
                      >
                        <TableCell>{moment(estimate.createAt).format("DD/MM/YYYY")}</TableCell>
                        <TableCell style={{ width: "20%" }}>
                          N°{estimate.number}
                          <br />
                          <span style={{ fontWeight: "bold" }}>{estimate.nature}</span>
                          <br />
                          {estimate.user && `par ${estimate.user.firstname} ${estimate.user.lastname.toUpperCase()}`}
                          {estimate.additionalDocuments && estimate.additionalDocuments.length > 0 && (
                            <Fragment>
                              <br />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                onClick={() => {
                                  this.handleShowLinkedAdditionalDocumentsDialog(estimate);
                                }}
                              >
                                + {estimate.additionalDocuments.length} document
                                {estimate.additionalDocuments.length > 1 && "s"} lié
                                {estimate.additionalDocuments.length > 1 && "s"}
                              </Typography>
                            </Fragment>
                          )}
                          {estimate.divisions && estimate.divisions.length > 1 && (
                            <Fragment>
                              <br />
                              <DocumentDivisionDetailsDialog
                                divisions={estimate.divisions}
                                title={`Vente partagée pour le devis ${estimate.number}`}
                                currentUser={sessionStore.user}
                              />
                            </Fragment>
                          )}
                        </TableCell>
                        {estimate.contact ? (
                          <TableCell>
                            {estimate.contact.businessName ? (
                              <Fragment>
                                {estimate.contact.businessName}
                                <br />({estimate.contact.firstname} {estimate.contact.lastname.toUpperCase()})
                              </Fragment>
                            ) : (
                              <Fragment>
                                {estimate.contact.firstname} {estimate.contact.lastname.toUpperCase()}
                              </Fragment>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {estimate.clientBusinessName}
                            {estimate.clientBusinessName && <br />}
                            {`${estimate.clientBusinessName && "("}${
                              estimate.clientFirstname
                            } ${estimate.clientLastname.toUpperCase()}${estimate.clientBusinessName && ")"}`}
                          </TableCell>
                        )}

                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(estimate.totalPriceWithoutTVA, estimate.currency)}
                        </TableCell>
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(
                            estimate.totalPriceWithTVA - estimate.totalPriceWithoutTVA,
                            estimate.currency
                          )}
                        </TableCell>
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(estimate.totalPriceWithTVA, estimate.currency)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <EstimateStatusLabel
                            estimate={estimate}
                            canBeManaged={
                              sessionStore.user.id === estimate.user.id ||
                              sessionStore.userHasAccess({
                                requiredAcls: ["ESTIMATE_WRITE"],
                                requiredFeatures: ["ESTIMATE"]
                              })
                            }
                            handleChangeStatus={() => {
                              this.handleShowStatusManager(estimate);
                            }}
                          />
                        </TableCell>
                        <TableCell>{this.renderMenu(estimate)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center" className={classes.loadingCell}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {estimates && estimates.length >= 0 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
              )}
            </Paper>
          </Grid>
          {downloadHref !== "" && (
            <a ref={this.simulateClick} href={downloadHref} download={download} style={{ display: "none" }}>
              Download
            </a>
          )}
        </Grid>
        <EstimateStatusManager
          onClose={this.handleHideStatusManager}
          handleSubmit={this.markEstimateAs}
          open={openStatusManager}
          estimate={selectedEstimate}
        />

        <MobileConfirmationDialog
          estimate={selectedEstimate}
          onClose={this.handleHideConfirmMobileForSignature}
          open={openConfirmMobileDialog}
          onConfirm={() => {
            this.fetchEstimates();
          }}
        />

        <PreviewEstimateDialog onClose={this.handleHidePreview} open={openPreview} estimate={selectedEstimate} />
        {openYouSignDialog && (
          <EstimateYouSignDialog
            currentUser={sessionStore.user}
            open={openYouSignDialog}
            doc={selectedEstimate}
            handleClose={this.handleCloseYouSignDialog}
          />
        )}
        <LinkedInvoicesDialog
          onClose={this.handleHideLinkedInvoices}
          open={openLinkedInvoices}
          estimate={selectedEstimate}
        />
        <LinkedAdditionalDocumentsDialog
          onClose={this.handleHideLinkedAdditionalDocumentsDialog}
          open={openLinkedAdditionalDocumentsDialog}
          estimate={selectedEstimate}
        />
        <FranfinanceTransferTypeDialog
          onClose={this.handleHideFranfinanceTransferTypeDialog}
          open={!!selectedEstimate && openFranfinanceContextTransferTypeDialog}
          onSubmit={this.handleSubmitFranfinanceTransferTypeDialog}
        />
      </Fragment>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(EstimatesList)));
